import { TFunction } from 'i18next';
import { CurrencyEnum } from '../../../../utils/currency';

export const getCurrencyOptions = (t: TFunction<'translation', undefined, 'translation'>) => [
  { label: 'Грн', value: CurrencyEnum.UAH },
  { label: t('currency.usd'), value: CurrencyEnum.USD },
  { label: t('currency.mdl'), value: CurrencyEnum.MDL },
  { label: 'Тенге', value: CurrencyEnum.KZT },
  { label: t('currency.uzs'), value: CurrencyEnum.UZS }
];
