import React from 'react';
import { ShortHorizontalArrowIcon } from '../../../ui/Icons';
import s from './ChangeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetProjectCrossSalesQuery } from '../../../../store/api';
import { Loading } from '../../../ui/Loading';
import { CrossSaleBlock } from '../../CrossSalePage';
import { CrossSalesOrderChangesProps } from './types';

const CrossSalesOrderChanges: React.FC<CrossSalesOrderChangesProps> = ({ projectId, orders }) => {
  const { t } = useTranslation();
  const { data: cross_sales, isLoading: isLoadingOnCrossSales } =
    useGetProjectCrossSalesQuery(projectId);

  const oldCrossSales = cross_sales?.data
    .filter((item) => orders.old.includes(item.id))
    .sort((a, b) => orders.old.indexOf(a.id) - orders.old.indexOf(b.id));
  const newCrossSales = cross_sales?.data
    .filter((item) => orders.new.includes(item.id))
    .sort((a, b) => orders.new.indexOf(a.id) - orders.new.indexOf(b.id));

  const addedIds = orders.new.filter((id) => !orders.old.includes(id));
  const movedIds = orders.new.filter((id, index) => {
    const oldIndex = orders.old.indexOf(id);
    return oldIndex !== -1 && oldIndex !== index;
  });

  const changedIds = new Set([...addedIds, ...movedIds]);

  return (
    <div>
      <div className={s.changes}>
        <div className={s.block}>
          <div className={s.value}>
            <div className={s.list}>
              {oldCrossSales?.length
                ? oldCrossSales.map((crossSale) => (
                    <div key={crossSale.id}>
                      <CrossSaleBlock cross_sale={crossSale} />
                    </div>
                  ))
                : '-'}
            </div>
          </div>
          <div className={s.before}>{t('dataLoggingPage.before')}</div>
        </div>
        <ShortHorizontalArrowIcon className={s.arrow} />
        <div className={s.block}>
          <div className={s.value}>
            <div className={s.list}>
              {newCrossSales?.length
                ? newCrossSales.map((crossSale) => (
                    <div
                      key={crossSale.id}
                      className={changedIds.has(crossSale.id) ? s.greenBorder : ''}
                    >
                      <CrossSaleBlock cross_sale={crossSale} />
                    </div>
                  ))
                : '-'}
            </div>
          </div>
          <div className={s.after}>{t('dataLoggingPage.after')}</div>
        </div>
      </div>
      {isLoadingOnCrossSales && <Loading />}
    </div>
  );
};
export default CrossSalesOrderChanges;
