export enum CurrencyEnum {
  USD = 'usd',
  MDL = 'mdl',
  KZT = 'kzt',
  UZS = 'uzs',
  UAH = 'uah'
}

export const getCurrency = (currency: CurrencyEnum) => {
  switch (currency) {
    case CurrencyEnum.USD:
      return ' $';
    case CurrencyEnum.MDL:
      return ' лей';
    case CurrencyEnum.KZT:
      return ' тенге';
    case CurrencyEnum.UZS:
      return ' сум';
    default:
      return ' грн';
  }
};
