import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Title } from '../components/ui/Title';
import { Breadcrumbs } from '../components/ui/Breadcrumbs';
import { RouteNames, changeTypes, entityTypes } from '../constants';
import { LoggingTable, ScriptsCount } from '../components/modules/DataLoggingPage';
import { Select } from '../components/ui/Select';
import { DatePick } from '../components/ui/DatePick';
import { CloseFillIcon, VerticalArrowIcon } from '../components/ui/Icons';
import { Pagination } from '../components/ui/Pagination';
import { ISelectOption } from '../components/ui/Select/DefaultSelect/types';
import { SingleValue } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllLoggingQuery, useGetProjectQuery, useGetScriptsQuery } from '../store/api';
import { Loading } from '../components/ui/Loading';

const DataLogging: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));

  const [entityType, setEntityType] = useState<ISelectOption | null>(null);
  const [changeType, setChangeType] = useState<ISelectOption | null>(null);
  const [scriptQuery, setScriptQuery] = useState<ISelectOption | null>(null);
  const [scriptInternalId, setScriptInternalId] = useState<ISelectOption | null>(null);
  const [dateTo, setDateTo] = useState<number | undefined>();
  const [dateFrom, setDateFrom] = useState<number | undefined>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [orderDate, setOrderDate] = useState('DESC');
  const [orderScript, setOrderScript] = useState('ASC');

  const { data: project, isLoading: projectLoading } = useGetProjectQuery(project_id);
  const { data: scripts, isLoading: scriptsLoading } = useGetScriptsQuery(project_id);
  const { data: loggingData, isLoading: loggingDataLoading } = useGetAllLoggingQuery({
    project_id,
    script_id: Number(scriptQuery?.value) || Number(scriptInternalId?.value),
    sort_script_name: orderScript,
    sort_date: orderDate,
    change_type: Number(changeType?.value),
    entity_type: Number(entityType?.value),
    per_page: perPage,
    page,
    date_to: dateTo,
    date_from: dateFrom
  });
  const { data, pagination } = loggingData || {};
  const isPagination = pagination && pagination.total / pagination.per_page > 1;
  const scriptsOptions = scripts?.data.scripts.map((item) => ({
    label: item.name,
    value: item.id
  }));
  const scriptsIds = scripts?.data.scripts.map((item) => ({
    label: item.internal_id,
    value: item.id
  }));

  const handleChangeScriptQuery = (newQuery: SingleValue<ISelectOption>) => {
    setScriptQuery(newQuery);
    setScriptInternalId(null);
  };

  const handleChangeInternalId = (newInternalId: SingleValue<ISelectOption>) => {
    setScriptInternalId(newInternalId);
    setScriptQuery(null);
  };

  const handleEntityType = (newType: SingleValue<ISelectOption>) => {
    setEntityType(newType);
  };

  const handleChangeType = (newType: SingleValue<ISelectOption>) => {
    setChangeType(newType);
  };

  const handleClearScriptQuery = () => {
    setScriptQuery(null);
  };

  const handleClearInternalId = () => {
    setScriptInternalId(null);
  };

  const handleClearEntityType = () => {
    setEntityType(null);
  };

  const handleClearChangeType = () => {
    setChangeType(null);
  };

  const handleChangeDateTo = (date: number) => {
    setDateTo(date);
  };

  const handleChangeDateFrom = (date: number) => {
    setDateFrom(date);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangePerPage = (count: number) => {
    setPerPage(count);
  };

  const handleChangeOrderDate = () => {
    orderDate === 'ASC' ? setOrderDate('DESC') : setOrderDate('ASC');
  };

  const handleChangeOrderScript = () => {
    orderScript === 'ASC' ? setOrderScript('DESC') : setOrderScript('ASC');
  };

  if (!project_id) {
    navigate(RouteNames.DATA_LOGGING + '/projects');
  }

  return (
    <div className="data-logging" id="data-logging">
      <div className="container--greatest">
        {!projectLoading && !scriptsLoading && project ? (
          <div className="data-logging__inner">
            <Title title={t('dataLogging')} variant="h1" textAlign="center" />
            <Breadcrumbs
              crumbs={[
                { title: 'Дім', href: '/' },
                {
                  title: project?.data.name,
                  href: `${RouteNames.DATA_LOGGING}/projects`
                },
                {
                  title: t('dataLogging'),
                  href: `${RouteNames.DATA_LOGGING}?project_id=${project_id}`
                }
              ]}
              className="data-logging__breadcrumbs"
            />
            <div className="data-logging__table-wrapper">
              <div className="data-logging__filters">
                {scriptsIds && (
                  <div className="data-logging__filter-wrapper">
                    <div
                      className={cn('close-btn', { active: scriptInternalId })}
                      onClick={handleClearInternalId}
                    >
                      <CloseFillIcon />
                    </div>
                    <Select
                      name="internal_id"
                      value={scriptInternalId}
                      onChange={handleChangeInternalId}
                      options={scriptsIds}
                      isSearchable
                      placeholder={'ID'}
                      className="data-logging__filter"
                    />
                  </div>
                )}
                {scriptsOptions && (
                  <div className="data-logging__filter-wrapper">
                    <div
                      className={cn('close-btn', { active: scriptQuery })}
                      onClick={handleClearScriptQuery}
                    >
                      <CloseFillIcon />
                    </div>
                    <Select
                      name="script_name"
                      value={scriptQuery}
                      onChange={handleChangeScriptQuery}
                      options={scriptsOptions}
                      isSearchable
                      placeholder={'Скрипт'}
                      className="data-logging__filter"
                    />
                  </div>
                )}
                <DatePick
                  defaultDate="2023-12-01T00:00:00"
                  changeDate={handleChangeDateFrom}
                  startDay
                  className="datepicker-input datepicker-input--start"
                />
                <VerticalArrowIcon className="separator-arrow" />
                <DatePick
                  changeDate={handleChangeDateTo}
                  endDay
                  className="datepicker-input datepicker-input--end"
                />
                <div className="data-logging__filter-wrapper">
                  <div
                    className={cn('close-btn', { active: changeType })}
                    onClick={handleClearChangeType}
                  >
                    <CloseFillIcon />
                  </div>
                  <Select
                    name="change-type"
                    value={changeType}
                    onChange={handleChangeType}
                    options={changeTypes(t)}
                    placeholder={t('dataLoggingPage.change')}
                    className="data-logging__filter"
                  />
                </div>
                <div className="data-logging__filter-wrapper">
                  <div
                    className={cn('close-btn', { active: entityType })}
                    onClick={handleClearEntityType}
                  >
                    <CloseFillIcon />
                  </div>
                  <Select
                    name="entity-type"
                    value={entityType}
                    onChange={handleEntityType}
                    options={entityTypes(t)}
                    placeholder={t('dataLoggingPage.entity')}
                    className="data-logging__filter"
                  />
                </div>
              </div>
              {!!data?.length && (
                <div className="data-logging__table">
                  <LoggingTable
                    changes={data}
                    changeOptions={changeTypes(t)}
                    entityOptions={entityTypes(t)}
                    orderDate={orderDate}
                    orderScript={orderScript}
                    changeOrderDate={handleChangeOrderDate}
                    changeOrderScript={handleChangeOrderScript}
                  />
                  <div className="data-logging__pagination">
                    <ScriptsCount
                      total={pagination?.total ?? 0}
                      page={page}
                      perPage={pagination?.per_page ?? 0}
                      onCountChange={handleChangePerPage}
                    />
                    {isPagination && (
                      <Pagination
                        itemsCount={pagination.total}
                        itemsPerPage={pagination.per_page}
                        onPageChange={handleChangePage}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            {!data?.length && !loggingDataLoading && (
              <p className="empty">{t('dataLoggingPage.changesNotFound')}</p>
            )}
            {loggingDataLoading && <Loading />}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default DataLogging;
