import React from 'react';
import s from './ChangeModal.module.scss';
import { ShortHorizontalArrowIcon } from '../../../ui/Icons';
import { ValueChangesProps } from './types';
import { useTranslation } from 'react-i18next';
import diff from '../../../../libs/htmlDiff';

const ValueChanges: React.FC<ValueChangesProps> = ({
  title_new,
  text_old,
  text_new,
  title_old,
  price
}) => {
  const { t } = useTranslation();

  const changedValueTitle = diff(title_old, title_new);
  const changedValueText = diff(text_old, text_new);
  const changedCrossSalesNewPrice = diff(price?.new_price.old, price?.new_price.new);
  const changedCrossSalesOldPrice = diff(price?.old_price.old, price?.old_price.new);

  return (
    <div className={s.changes}>
      <div className={s.block}>
        <div className={s.value}>
          <div className={s.title}>{title_old}</div>
          {price && (
            <div className={s.prices}>
              <div>
                <span>{t('crossSalePage.newPrice')}:</span> <span>{price?.new_price.old}</span>
              </div>
              <div>
                <span>{t('crossSalePage.oldPrice')}:</span> <span>{price?.old_price.old}</span>
              </div>
            </div>
          )}
          <div className={s.text} dangerouslySetInnerHTML={{ __html: text_old }} />
        </div>
        <div className={s.before}>{t('dataLoggingPage.before')}</div>
      </div>
      <ShortHorizontalArrowIcon className={s.arrow} />
      <div className={s.block}>
        <div className={s.value}>
          <div className={s.title} dangerouslySetInnerHTML={{ __html: changedValueTitle }} />
          {price && (
            <div className={s.prices}>
              <div>
                <span>{t('crossSalePage.newPrice')}:</span>{' '}
                <span dangerouslySetInnerHTML={{ __html: changedCrossSalesNewPrice }} />
              </div>
              <div>
                <span>{t('crossSalePage.oldPrice')}:</span>{' '}
                <span dangerouslySetInnerHTML={{ __html: changedCrossSalesOldPrice }} />
              </div>
            </div>
          )}
          <div className={s.text} dangerouslySetInnerHTML={{ __html: changedValueText }} />
        </div>
        <div className={s.after}>{t('dataLoggingPage.after')}</div>
      </div>
    </div>
  );
};
export default ValueChanges;
