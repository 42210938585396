import React from 'react';
import cn from 'classnames';
import { ChangeModalProps } from './types';
import { Modal } from '../../../ui/Modals';
import s from './ChangeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetDetailedLoggingQuery } from '../../../../store/api';
import { Loading } from '../../../ui/Loading';
import CrossSalesOrderChanges from './CrossSalesOrderChanges';
import ValueChanges from './ValueChanges';
import ScriptChanges from './ScriptChanges';

const ChangeModal: React.FC<ChangeModalProps> = ({ open, onClose, changeId, projectId }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetDetailedLoggingQuery(changeId);

  const withScriptChanges = data?.data.price_old && data?.data.internal_id_old;
  const withValueChanges = data?.data.title_old && data?.data.text_old;
  const withCrossSalesOrderChanges = data?.data.extraData?.crossSalesOrder;

  const withSeparator =
    (withScriptChanges && withValueChanges) || (withScriptChanges && withCrossSalesOrderChanges);
  const withoutChanges = !withScriptChanges && !withValueChanges && !withCrossSalesOrderChanges;

  return (
    <Modal
      title={t('dataLoggingPage.change')}
      titleCentered
      open={open}
      onClose={onClose}
      contentClassName={s.content}
      bodyClassName={s.body}
    >
      <div className={s.changes__wrappper}>
        {!isLoading ? (
          <>
            {withScriptChanges && (
              <ScriptChanges
                price_new={data.data.price_new}
                price_old={data.data.price_old}
                article_new={data.data.article_new}
                article_old={data.data.article_old}
                internal_id_new={data.data.internal_id_new}
                internal_id_old={data.data.internal_id_old}
              />
            )}
            {withSeparator && <div className={s.separator} />}
            {withValueChanges && (
              <ValueChanges
                title_new={data?.data.title_new}
                title_old={data?.data.title_old}
                text_new={data?.data.text_new}
                text_old={data?.data.text_old}
                price={data?.data.price}
              />
            )}
            {withCrossSalesOrderChanges && (
              <CrossSalesOrderChanges
                projectId={projectId}
                orders={data.data.extraData.crossSalesOrder}
              />
            )}
            {withoutChanges && <p className={cn(s.clear, 'empty')}>{t('withoutChanges')}</p>}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </Modal>
  );
};

export default ChangeModal;
