import React from 'react';
import s from './ChangeModal.module.scss';
import { ShortHorizontalArrowIcon } from '../../../ui/Icons';
import { ScriptChangesProps } from './types';
import { useTranslation } from 'react-i18next';
import diff from '../../../../libs/htmlDiff';

const ScriptChanges: React.FC<ScriptChangesProps> = ({
  price_new,
  price_old,
  article_new,
  article_old,
  internal_id_new,
  internal_id_old
}) => {
  const { t } = useTranslation();

  const changedScriptPrice = diff(price_old, price_new);
  const changedScriptArticle = diff(article_old, article_new);
  const changedScriptInternalId = diff(internal_id_old, internal_id_new);

  return (
    <div className={s.changes}>
      <div className={s.block}>
        <div className={s.value}>
          <div className={s.item}>
            <span className={s.header}>{t('price')}:</span> <span>{price_old}</span>
          </div>
          {article_old && (
            <div className={s.item}>
              <span className={s.header}>{t('articul')}:</span> <span>{article_old}</span>
            </div>
          )}
          <div className={s.item}>
            <span className={s.header}>ID:</span> <span>{internal_id_old}</span>
          </div>
        </div>
        <div className={s.before}>{t('dataLoggingPage.before')}</div>
      </div>
      <ShortHorizontalArrowIcon className={s.arrow} />
      <div className={s.block}>
        <div className={s.value}>
          <div className={s.item}>
            <span className={s.header}>{t('price')}:</span>{' '}
            <span dangerouslySetInnerHTML={{ __html: changedScriptPrice }} />
          </div>
          {changedScriptArticle && (
            <div className={s.item}>
              <span className={s.header}>{t('articul')}:</span>{' '}
              <span dangerouslySetInnerHTML={{ __html: changedScriptArticle }} />
            </div>
          )}
          <div className={s.item}>
            <span className={s.header}>ID:</span>{' '}
            <span dangerouslySetInnerHTML={{ __html: changedScriptInternalId }} />
          </div>
        </div>
        <div className={s.after}>{t('dataLoggingPage.after')}</div>
      </div>
    </div>
  );
};
export default ScriptChanges;
