import React from 'react';
import Select from '../DefaultSelect/Select';
import { getCurrencyOptions } from './options';
import { CurrencyEnum } from '../../../../utils/currency';
import { CurrencySelectProps } from './types';
import { useTranslation } from 'react-i18next';

const CurrencySelect: React.FC<CurrencySelectProps> = ({ currency, onChangeCurrency }) => {
  const { t } = useTranslation();
  const options = getCurrencyOptions(t);

  return (
    <Select
      name="currency"
      value={options.find((item) => item.value === currency)}
      onChange={(e) => onChangeCurrency(e?.value as CurrencyEnum)}
      options={options}
    />
  );
};
export default CurrencySelect;
