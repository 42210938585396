import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Select } from '../../../ui/Select';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../constants';
import { ISelectOption } from '../../../ui/Select/DefaultSelect/types';
import { Input } from '../../../ui/Inputs';
import { Button } from '../../../ui/Buttons';
import s from './AddAccountForm.module.scss';
import { useFormik } from 'formik';
import { AddAccountFormProps, AddAccountValues } from './types';
import { loginValidate } from '../../../../utils/validate';
import { ShuffleIcon } from '../../../ui/Icons';
import AccountView from '../AccountView/AccountView';
import { SingleValue } from 'react-select';
import { useDeleteMacMutation, useGetFullUserQuery } from '../../../../store/api';
import { generatePassword } from '../../../../utils/generatePassword';
import { YesNoModal } from '../../../ui/Modals';
import { AuthContext } from '../../../../context/Auth/AuthContext';

const AddAccountForm: React.FC<AddAccountFormProps> = ({
  addAccount,
  editAccount,
  onClose,
  editAccountId
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMac] = useDeleteMacMutation();
  const editedAccountRes = useGetFullUserQuery(editAccountId, { skip: !editAccountId });
  const editedAccount = useMemo(() => editedAccountRes.data?.user, [editedAccountRes]);
  const [role, setRole] = useState<ISelectOption | null>(null);
  const [emptyRole, setEmptyRole] = useState(false);
  const [newPhoto, setNewPhoto] = useState<Blob | string | undefined>(undefined);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const options =
    user?.role === Roles.SUPERADMIN
      ? [
          { label: t('admin'), value: Roles.ADMIN },
          { label: t('manager'), value: Roles.MANAGER }
        ]
      : [{ label: t('manager'), value: Roles.MANAGER }];

  const formik = useFormik({
    initialValues: { last_name: '', first_name: '', middle_name: '', login: '', password: '' },
    enableReinitialize: true,
    validationSchema: loginValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AddAccountValues) => {
      if (!role) {
        setEmptyRole(true);
        return;
      }
      if (editedAccount) {
        await editAccount({
          ...editedAccount,
          ...values,
          role: Number(role.value),
          photo: newPhoto ?? undefined
        });
      } else {
        await addAccount({ ...values, role: Number(role.value), photo: newPhoto ?? undefined });
      }
    }
  });

  const handleDeleteMac = () => {
    editedAccount && deleteMac(editedAccount.id);
    setDeleteModal(false);
  };

  const handleChangeRole = (role: SingleValue<ISelectOption>) => {
    setRole(role);
  };

  const handleChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    setNewPhoto(file);
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    formik.setFieldValue('password', password);
  };

  useEffect(() => {
    if (editedAccount) {
      formik.setValues({
        last_name: editedAccount.last_name ? editedAccount.last_name : '',
        first_name: editedAccount.first_name ? editedAccount.first_name : '',
        middle_name: editedAccount.middle_name ? editedAccount.middle_name : '',
        login: editedAccount.login,
        password: editedAccount.password
      });
      setRole(
        user?.role === Roles.SUPERADMIN
          ? options[editedAccount.role === Roles.MANAGER ? 1 : 0]
          : options[0]
      );
      setNewPhoto(editedAccount.photo);
    }
  }, [editedAccount]);

  return (
    <div className={s.body}>
      <div className={s.view}>
        <AccountView
          last_name={formik.values.last_name}
          first_name={formik.values.first_name}
          middle_name={formik.values.middle_name}
          role={role?.label}
          login={formik.values.login}
          password={formik.values.password}
          photo={newPhoto}
          changePhoto={handleChangePhoto}
        />
      </div>
      <form className={s.form} onSubmit={formik.handleSubmit}>
        <div className={s.select}>
          <Select
            placeholder={t('accountsPage.role')}
            name="role"
            options={options}
            value={role}
            onChange={handleChangeRole}
            className={emptyRole ? s.emptyRole : ''}
          />
        </div>
        <div className={s.fields}>
          <div className={s.fullName}>
            <div className={s.field}>
              <Input
                name="last_name"
                error={!!formik.errors.last_name}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                placeholder={`${t('accountsPage.lastName')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="first_name"
                error={!!formik.errors.first_name}
                onChange={formik.handleChange}
                value={formik.values.first_name}
                placeholder={`${t('accountsPage.firstName')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="middle_name"
                error={!!formik.errors.middle_name}
                onChange={formik.handleChange}
                value={formik.values.middle_name}
                placeholder={`${t('accountsPage.middleName')}`}
              />
            </div>
          </div>
          <div className={s.field}>
            <Input
              name="login"
              error={!!formik.errors.login}
              onChange={formik.handleChange}
              value={formik.values.login}
              placeholder={`${t('accountsPage.login')}`}
              className={s.login}
            />
          </div>
          <div className={s.field}>
            <Input
              name="password"
              error={!!formik.errors.password}
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder={`${t('password')}`}
              className={s.password}
            />
            <div className={s.generate} onClick={handleGeneratePassword}>
              <ShuffleIcon />
            </div>
          </div>
          {editedAccount && (
            <div className={s.field}>
              <div className={s.mac}>
                <p className={s.mac__title}>MAC:</p>
                <Input
                  name="mac"
                  value={editedAccount.mac ?? '-'}
                  className={s.mac__input}
                  disabled={true}
                />
                <Button className={s.mac__delete} onClick={handleOpenDeleteModal} outlined>
                  {t('buttons.delete')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={s.btns}>
          <Button type="submit">{editedAccount ? t('buttons.edit') : t('buttons.save')}</Button>
          <Button onClick={onClose} red>
            {t('buttons.cancel')}
          </Button>
        </div>
      </form>
      <YesNoModal
        isDelete
        title={`MAC: ${editedAccount?.mac ?? '-'}`}
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onAgree={handleDeleteMac}
      />
    </div>
  );
};

export default AddAccountForm;
