import { TFunction } from 'i18next';

export const changeTypes = (t: TFunction<string[], undefined, string[]>) => {
  return [
    { label: `${t('dataLoggingPage.create')}`, value: 1 },
    { label: `${t('dataLoggingPage.edit')}`, value: 2 },
    { label: `${t('dataLoggingPage.delete')}`, value: 3 },
    { label: `${t('dataLoggingPage.archive')}`, value: 4 },
    { label: `${t('dataLoggingPage.unarchive')}`, value: 5 },
    { label: `${t('dataLoggingPage.crossSaleSetup')}`, value: 6 }
  ];
};
