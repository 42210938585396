import { TFunction } from 'i18next';

export const entityTypes = (t: TFunction<string[], undefined, string[]>) => {
  return [
    { label: `${t('dataLoggingPage.script')}`, value: 1 },
    { label: `${t('dataLoggingPage.field')}`, value: 2 },
    { label: `${t('projectsPage.regulations')}`, value: 3 },
    { label: `${t('scriptPage.info')}`, value: 4 },
    { label: `${t('crossSalesPage.crossSales')}`, value: 6 }
  ];
};
